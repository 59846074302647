import { get, isEmpty } from 'lodash';
import { Money } from 'ts-money';
import {
  orderStates,
  orderType,
  SIGNER_ENTITIES,
  US_CA_PHONE_NUMBER_PREFIX,
} from '../../constants/common.constants';
import { isAppliedSubsidyZero } from '../../utils/helpers';
import { SIGNATURE_ORDER_PAYLOAD } from './order.constants';
import { ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED } from '../../constants/order';

/**
 * Highlight rows for orders that require some user action
 * @param {object} order
 * @param {string} order.stage - Current stage of the order checkout flow
 * @param {boolean} order.needFundingInvoice - Whether user needs to upload funding invoice
 * @returns {*|boolean}
 */
export const isHighlighted = (order) => {
  // Yellow highlighted stages of order
  const SIGN_REQUIRED = 'signature_required';
  const APPROVAL_REQUIRED = 'approval_required';
  const REVIEW_PO = orderStates.review_purchase_order;

  const { stage, needFundingInvoice, anyPendingInvoicesOnMilestone, milestones } = order;

  const canUploadInvoiceForSingleMilestone =
    milestones?.length === 1 && ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED.includes(stage);

  const canUploadInvoiceForMultipleMilestones =
    milestones?.length > 1 && ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED.includes(stage);

  if (canUploadInvoiceForSingleMilestone) return needFundingInvoice;

  if (canUploadInvoiceForMultipleMilestones) return anyPendingInvoicesOnMilestone;
  return [APPROVAL_REQUIRED, SIGN_REQUIRED, REVIEW_PO].includes(stage);
};

/*
    This Function Converts a string to float by keeping the traling zeros.
    example:
      - input = "1200.30"
      - output = 1200.30
    note: parseFloat removes trailing zeros.
*/

export const parseFloatWithTrailingZeros = (inputString) => {
  if (typeof inputString === 'string') {
    // Convert the string to a float
    const floatValue = parseFloat(inputString);

    // Check if the conversion is successful
    if (Number.isNaN(floatValue)) {
      return null;
    }

    // Adding the traling zeros back to the float
    const resultFloat = floatValue.toFixed(
      inputString.includes('.') ? inputString.split('.')[1].length : 0
    );

    return resultFloat;
  }
  return inputString;
};

export const formatDate = (date) => {
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  if (formattedDate === 'Invalid Date') {
    return null;
  }
  return formattedDate;
};

export const getProposalPayload = (
  proposal,
  index,
  isOrderEdit,
  getProposalSpiffRate,
  isCustomSchedule
) => {
  const applyOfferedBuyerInterestRate =
    !!proposal.interestRate && proposal.interestRate !== 'null' && !isCustomSchedule;
  const proposalFields = {
    uuid: proposal.key,
    title: proposal.title,
    sortOrder: index + 1,
    orderType:
      proposal.orderType === orderType.installments
        ? proposal.orderType
        : orderType.pay_in_full,
    startDate: new Date(proposal.startDate),
    endDate: new Date(proposal.endDate),
    useVartanaFinancing: proposal.vartanaFinancing,
    isDollar: proposal.vartanaFinancing && proposal.isDollar,
    offeredBuyerInterestRate: proposal.interestRate
      ? parseFloat(proposal.interestRate)
      : 0,
    applyOfferedBuyerInterestRate,
  };
  const dollarSubsidy = proposal.isDollar ? parseFloat(proposal.subsidy) : 0;
  const percentageSubsidy = !proposal.isDollar ? parseFloat(proposal.subsidy) : 0;
  const baseSubsidy = proposal.isDollar
    ? proposal.subsidy / proposal.amount
    : proposal.subsidy / 100;

  if (isOrderEdit) {
    proposalFields.number = proposal.number || null;
    proposalFields.orderType =
      proposal.orderType === orderType.installments
        ? orderType.installments
        : orderType.pay_in_full;
  }
  switch (proposal.orderType) {
  case orderType.full_payment:
    return {
      ...proposalFields,
      ...SIGNATURE_ORDER_PAYLOAD,
    };
  default:
    return {
      ...proposalFields,
      amount: proposal.amount,
      billingFrequency: proposal.paymentFrequency || null,
      paymentTerm: proposal.paymentTerm,
      term:
          proposal.orderType === orderType.pay_in_full
            ? 0
            : +proposal.contractLength || 0,
      blindDiscount: applyOfferedBuyerInterestRate ? 0 : baseSubsidy,
      dollarBlindDiscount: applyOfferedBuyerInterestRate ? 0 : dollarSubsidy,
      percentageBlindDiscount: applyOfferedBuyerInterestRate ? 0 : percentageSubsidy,
      spiffRate: getProposalSpiffRate(proposal),
    };
  }
};

export const generateDocsMeta = (proposals) => {
  const docs = [];
  const docsMeta = [];
  proposals
    .filter((proposal) => !isEmpty(proposal.orderForm))
    .forEach((proposal) => {
      proposal.orderForm.forEach((vendorDoc) => {
        if (vendorDoc.size) {
          docs.push(vendorDoc);
          docsMeta.push(proposal.key);
        }
      });
    });
  return { docs, docsMeta };
};

export const getSignerDetails = (orderBuyer, orderState) => {
  const isPGOrder = get(orderBuyer, 'creditCheck.pgRequired', false);
  const isCCGOrder = get(orderBuyer, 'creditCheck.conditionalToCcg', false);

  if (isPGOrder) return get(orderBuyer, 'primaryUser', {});
  if (isCCGOrder && orderState === orderStates.corporate_guarantee)
    return get(orderBuyer, 'creditCheck.corporateGuarantor.primaryUser', {});

  // return empty object if no guarantor is required
  return {};
};

// Define a currency object manually
const USD = {
  code: 'USD',
  minorUnit: 2, // USD has 2 minor units (cents)
};
export const centsToDollars = (cents) => {
  if (!cents) {
    return null;
  }
  const money = new Money(cents, USD);
  const dollars = (money.amount / 100).toFixed(2);
  return dollars;
};
// adding prefix to the phone number if it does not exist
export const getUsCaFormattedPhoneNumber = (selectedOption) => {
  return selectedOption?.Phone?.includes(US_CA_PHONE_NUMBER_PREFIX)
    ? selectedOption?.Phone
    : `${US_CA_PHONE_NUMBER_PREFIX} ${selectedOption.Phone}`;
};

export function computeClosestTerm(term, termsObject) {
  const targetTerm = parseInt(term, 10);
  // Check if the exact term exists in the termsObject
  // added disable eslint rule for hasOwnProperty
  // eslint-disable-next-line no-prototype-builtins
  if (termsObject.hasOwnProperty(term)) {
    return term; // Return the exact key if it exists
  }

  // Get the available terms as integers and sort them in ascending order
  const availableTerms = Object.keys(termsObject)
    .map((key) => parseInt(key, 10))
    .filter((key) => !Number.isNaN(key)) // Remove any NaN entries
    .sort((a, b) => a - b);

  // If no terms available, return null
  if (!availableTerms.length) {
    return null;
  }

  // If the term is greater than the maximum available term, return null
  if (targetTerm > availableTerms[availableTerms.length - 1]) {
    return availableTerms[0];
  }

  // Binary search to find the closest term greater than or equal to targetTerm
  let left = 0;
  let right = availableTerms.length - 1;
  let result = null;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (availableTerms[mid] === targetTerm) {
      return availableTerms[mid]; // Exact match found
    }
    if (availableTerms[mid] < targetTerm) {
      left = mid + 1; // Search in the right half
    } else {
      result = availableTerms[mid]; // Possible candidate, continue to find smaller greater value
      right = mid - 1; // Search in the left half to find the closest greater or equal value
    }
  }

  return result;
}
export const getBackfilledSubsidy = (appliedSubsidy, isDollar) => {
  const isSubsidyZero = isAppliedSubsidyZero(appliedSubsidy?.percentage);
  const percentageAppliedSubsidy = isSubsidyZero ? null : appliedSubsidy?.percentage;

  const dollarAppliedSubsidy = !appliedSubsidy?.cents
    ? null
    : centsToDollars(appliedSubsidy?.cents);

  return isDollar ? dollarAppliedSubsidy : percentageAppliedSubsidy;
};

export const checkIsFinancingOrder = (proposals) => {
  const isFinancedOrder = proposals?.some((proposal) =>
    [orderType.installments, orderType.pay_in_full].includes(proposal?.orderType)
  );
  return isFinancedOrder;
};

export const getAuthorizedSignerStructure = (customerName) => {
  return {
    label: 'Authorized signer',
    description: `The person below is responsible for signing agreements on behalf of ${customerName}`,
    signatureEntity: 'buyer',
    signatureOrder: 1,
  };
};

export const getIsAuthorizedSigner = (signerDetails) => {
  return (
    signerDetails.signatureEntity === SIGNER_ENTITIES.BUYER &&
    signerDetails.signatureOrder === 1
  );
};

// This method is used to get the authorized signer from a list of signers
export const getAuthorizedSigner = (signers) => {
  const authorizedSignerOrder = 1;
  if (signers.length)
    return (
      signers.find(
        (signer) =>
          signer.signatureEntity === SIGNER_ENTITIES.BUYER &&
          signer.signatureOrder === authorizedSignerOrder
      ) || {}
    );
  return {};
};

// This method is used to get the highest tcv from the proposals
export const getHighestTcv = (proposals) => {
  if (!Array.isArray(proposals) || proposals.length === 0) {
    return 0; // return 0 if proposals aren't populated
  }

  return proposals.reduce((prev, current) =>
    prev?.amount > current?.amount ? prev : current
  ).amount;
};
export const getFilteredCreditTerm = (approvedCreditTerms, paymentType, contractLength) =>
  approvedCreditTerms.find((term) => {
    // Extract the first key from the current object (assumes one key only)
    const key = Object.keys(term)?.[0];

    // Check if the order type is 'full_payment' and current credit term is 0
    if (paymentType === orderType.pay_in_full && key === '0') {
      return true;
    }

    // Check if the order type is 'installment_plan'
    if (paymentType === orderType.installments) {
      // if the order type is 'installment_plan' and current credit term is 0,
      // it should give whatever terms are available except 0
      if (contractLength === 0 && key !== '0') {
        return true;
      }

      // In case the current credit term is not 0, it should give the exact
      // credit term present against that key
      if (contractLength !== 0 && key === contractLength?.toString()) {
        return true;
      }
    }

    // If none of the conditions match, do not select this credit term
    return false;
  });
