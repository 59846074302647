import { gql } from '@apollo/client';

export const GET_SESSION_USER = gql`
  query getSessionUser {
    globalResellerPermissions: resellerGlobalPermissions
    session {
      isHijacked
      user {
        id
        fullName
        firstName
        lastName
        email
        createdAt
        updatedAt
        permissionSet
        userRole {
          name
        }
        company {
          number
          name
          logoUrl
          country
          domain
          vendorLogoUrl
          sellerType
          canActAsReseller
          useResellerWorkflow
          turnOnResellerMode
          hideResellerOrderDetails
          showMetabaseDashboard
          metabaseUrl: vendorGenerateMetabaseUrl
          salesQuoteRequiredAtCustomerCreation
          guaranteeTypeOnCustomerCreation
          enabledForBrokering
          product {
            allowBuyersToUploadPo
            allowedDurations
            useRequestedAmount
            enabledCountries
            enabledCurrencies
            multipleCountriesEnabled
            showRateToVendor
          }
        }
      }
      adminUser {
        email
      }
    }
  }
`;
