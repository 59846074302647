import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { InputFieldPatternFormat } from '@vartanainc/design-system';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';

function PatternInput({
  name,
  format,
  placeholder,
  label,
  value,
  mask,
  tooltipElement = <></>,
  disableFullStoryRecording,
  customClassName = '',
  ...rest
}) {
  const [field, meta, { setValue }] = useField(name);
  return (
    <InputFieldPatternFormat
      {...field}
      {...rest}
      placeholder={placeholder}
      value={value}
      format={format}
      mask={mask}
      fullWidth
      label={label}
      showError={meta.touched && meta.error}
      helperText={meta.error}
      tooltipIcon={tooltipElement ? <TooltipIcon /> : null}
      tooltipElement={tooltipElement}
      className={`${customClassName} ${disableFullStoryRecording ? 'fs-mask' : ''}`}
      onChange={({ formattedValue }) => {
        setValue(formattedValue);
      }}
    />
  );
}

PatternInput.propTypes = {
  name: PropTypes.string,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  mask: PropTypes.string,
  tooltipElement: PropTypes.node,
  customClassName: PropTypes.string,
  disableFullStoryRecording: PropTypes.bool,
};

PatternInput.defaultProps = {
  name: '',
  format: '+1 (###) ###-####',
  placeholder: '',
  label: '',
  value: '',
  mask: '',
  tooltipElement: null,
  customClassName: '',
  disableFullStoryRecording: false,
};

export default PatternInput;
