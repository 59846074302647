import { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import * as yup from 'yup';
import { Typography, Button } from '@vartanainc/design-system';

import { PermissionsContext } from '../../context/DashboardContext';
import { GET_SESSION_USER } from '../../graphql/queries/root';
import { handleSignIn, handleGoogleSignIn, handleMicrosoftSignIn } from '../../api/utils';
import { useQueryParams } from '../../utils/hooks';
import { convertKeysToCamelCase, showErrorToast } from '../../utils/helpers';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import loginBg from '../../assets/desktop-sign-in.svg';
import { ReactComponent as BackIcon } from '../../assets/back_icon_blue.svg';
import { ReactComponent as EyeIcon } from '../../assets/eye-icon.svg';
import { ReactComponent as EyeIconSlash } from '../../assets/eye-icon-slash.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon-16p.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/microsoft-icon-16p.svg';
import { ReactComponent as NextIcon } from '../../assets/next_icon.svg';
import Loader from '../../components/Loader';

import TextInputField from '../../designSystem/TextInput/TextInputField';
import MarketingText from './MarketingText';
import { useSsoHandler } from './sso-handler.hook';

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const SignIn = () => {
  const [error, setError] = useState('');
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const [showPasswordField, setShowPasswordField] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const location = useLocation();
  const { showModal, resetModal, openModal, setWindowSize, getWindowSize } =
    useSsoHandler();
  const redirectUri = useQueryParams().get('r');
  const { setPermissions } = useContext(PermissionsContext);

  const [getSessionData, { loading: sessionLoading }] = useLazyQuery(GET_SESSION_USER, {
    onCompleted: (response) => {
      setPermissions(
        convertKeysToCamelCase(
          get(response, 'session.user.permissionSet.role_permissions', {})
        )
      );
      // setting login successful to true to disable the sign in button
      // when the user is logged in waitng for the redirect
      setIsLoginSuccessful(true);
      window.location.href = redirectUri?.length > 0 ? redirectUri : '/dashboard';
    },
  });

  const handleSubmitForm = useCallback(
    (values) => {
      if (
        formErrors &&
        formValues &&
        (formErrors.password || formValues.password === '')
      ) {
        passwordRef.current?.focus();
      } else {
        setLoading(true);
        handleSignIn(values, navigate)
          .then((result) => {
            if (result?.success) getSessionData();
            else {
              const errorMessage = result?.error;
              setError(errorMessage);
            }
          })
          .catch(() => {
            showErrorToast();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [formErrors, formValues, getSessionData, navigate]
  );

  const handleGoogleSubmit = useCallback(() => {
    // Google button clicked
    handleGoogleSignIn().then((result) => {
      openModal(result);
    });
  }, [openModal]);

  const handleMicrosoftSubmit = useCallback(() => {
    // Microsoft button clicked
    handleMicrosoftSignIn().then((result) => {
      openModal(result);
    });
  }, [openModal]);

  const formikBag = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: signInFormSchema,
    onSubmit: async (...args) => {
      const [values] = args;
      handleSubmitForm(values);
    },
  });

  useEffect(() => {
    // Check if the new form values are different from the ones already set
    const formValuesChanged =
      formikBag.values.email !== formValues?.email ||
      formikBag.values.password !== formValues?.password;

    // Update form values only if they have changed
    if (formValuesChanged) {
      setFormValues(formikBag.values);
      // this is used to refresh the error state incase the user changes the password/email on sign in
      setError('');
    }

    setFormErrors(formikBag.errors);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
      resetModal();
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('focus', () => {
      resetModal();
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('focus', () => {
        resetModal();
      });
    };
  }, [
    formikBag.values,
    formikBag.errors,
    resetModal,
    setWindowSize,
    getWindowSize,
    formValues,
  ]);

  const handleNext = () => {
    if (formErrors && formValues && (formErrors.email || formValues.email === '')) {
      emailRef.current?.focus();
    } else {
      setShowPasswordField(true);
    }
  };

  const onBackClick = () => {
    setShowPasswordField(false);
    setError('');
    formikBag.values.password = '';
  };

  // The submit button is disabled if the login is successful,
  // there is an error, or the form is not valid.
  const disableSubmit = isLoginSuccessful || error || !formikBag.isValid;

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <img src={loginBg} alt="" className="absolute inset-0 h-full w-full object-cover" />
      <div className="w-full absolute flex justify-center lg:inset-0">
        <div className="px-8 py-8 flex flex-1 flex-col max-w-7xl gap-14 lg:py-16 lg:px-24 md:px-16">
          <LogoIcon className="h-auto w-40" />
          <div className="flex flex-col h-full justify-center gap-8 items-center lg:flex-row">
            <div className="flex grow-0 w-full justify-center">
              <MarketingText />
            </div>
            <div className="w-full bg-vartana-blue-100 rounded-b-lg rounded-t pt-1 max-w-lg h-auto grow-0 shadow-2xl lg:w-auto">
              <div className="w-full h-full bg-white rounded-b-lg ">
                <div className="flex justify-center p-10">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="mb-10">
                      <Typography variant="heading24" bold color="color-blue-180">
                        Sign in to your account{' '}
                      </Typography>
                      {showPasswordField && (
                        <button
                          className="flex mt-4 items-center gap-1"
                          onClick={onBackClick}
                        >
                          <BackIcon />
                          <Typography variant="heading14" bold color="color-blue-120">
                            Back to sign-in methods
                          </Typography>
                        </button>
                      )}
                    </div>
                    {!showPasswordField && (
                      <div className="gap-4 flex flex-col mb-10">
                        <Button
                          type="button"
                          size="large"
                          rounded
                          borderColor="color-gray-100"
                          backgroundColor="secondary"
                          startIcon={<GoogleIcon className="h-4 w-auto object-contain" />}
                          variant="outlined"
                          fullWidth
                          onClick={handleGoogleSubmit}
                        >
                          <Typography variant="heading14" bold color="color-blue-180">
                            Sign in with Google
                          </Typography>
                        </Button>
                        <Button
                          type="button"
                          size="large"
                          rounded
                          borderColor="color-gray-100"
                          backgroundColor="secondary"
                          startIcon={
                            <MicrosoftIcon className="h-4 w-auto object-contain" />
                          }
                          variant="outlined"
                          fullWidth
                          onClick={handleMicrosoftSubmit}
                        >
                          <Typography variant="heading14" bold color="color-blue-180">
                            Sign in with Microsoft
                          </Typography>
                        </Button>
                      </div>
                    )}
                    {!showPasswordField && (
                      <div className="gap-4 flex mb-6 items-center">
                        <hr className="w-full border-gray-40-v3"></hr>
                        <Typography variant="paragraph14" color="color-gray-100">
                          Or
                        </Typography>
                        <hr className="w-full border-gray-40-v3"></hr>
                      </div>
                    )}
                    <FormikProvider value={formikBag}>
                      <Form className="flex flex-col gap-4">
                        <TextInputField
                          id="email"
                          name="email"
                          type="email"
                          label="Work email"
                          autoComplete="off"
                          placeholder="example@email.com"
                          fullWidth
                          ref={emailRef}
                        />
                        {showPasswordField && (
                          <div>
                            <TextInputField
                              id="password"
                              name="password"
                              type={revealPassword ? 'text' : 'password'}
                              label="Password"
                              disableFullStoryRecording
                              autoComplete="off"
                              ref={passwordRef}
                              suffixIcon={
                                revealPassword ? (
                                  <EyeIconSlash className="h-5 w-auto object-contain" />
                                ) : (
                                  <EyeIcon className="h-5 w-auto object-contain" />
                                )
                              }
                              suffixIconFunc={() =>
                                setRevealPassword((currentValue) => !currentValue)}
                              fullWidth
                            />

                            {error !== '' ? (
                              <div className="flex items-center justify-between mt-2">
                                <Typography variant="paragraph10" color="color-red-160">
                                  {error}
                                </Typography>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                        {showPasswordField && (
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <input
                                id="remember_me"
                                name="remember_me"
                                type="checkbox"
                                className="h-4 w-4 text-v-blue focus:ring-indigo-500 border-gray-300 rounded"
                              />
                              <label
                                htmlFor="remember_me"
                                className="ml-2 block text-sm text-gray-900"
                              >
                                Remember me
                              </label>
                            </div>
                            <div className="text-sm">
                              <Link
                                to={`/login/forgot-password${location.search}`}
                                state={{ email: formValues.email }}
                                className="relative inline-flex font-medium vp-text-link text-vartana-blue-60 hover:text-indigo-500"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        )}

                        <div className="flex justify-center mt-2 mb-4">
                          {!showPasswordField && (
                            <Button
                              onClick={handleNext}
                              size="x-small"
                              BackgroundColor="primary"
                              endIcon={
                                <NextIcon className="h-4 w-auto object-cover fill-white" />
                              }
                            >
                              Next
                            </Button>
                          )}
                          {showPasswordField &&
                            (loading || sessionLoading ? (
                              <Loader className="w-8 h-8" />
                            ) : (
                              <Button
                                type="submit"
                                size="x-small"
                                BackgroundColor="primary"
                                disabled={disableSubmit}
                              >
                                Sign in
                              </Button>
                            ))}
                        </div>
                      </Form>
                    </FormikProvider>

                    <div className="flex justify-center gap-1">
                      <Typography variant="paragraph14" color="color-blue-180">
                        Need an account?
                      </Typography>
                      <Link
                        to={`/signup${location.search}`}
                        className="relative inline-flex font-medium vp-text-link text-vartana-blue-60 hover:text-indigo-500"
                      >
                        <Typography variant="heading14" bold>
                          Sign up
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed w-full h-full bg-vartana-gray-70 bg-opacity-80 flex justify-center items-center cursor-default"></div>
      )}
    </div>
  );
};

export default SignIn;
