import { useEffect, useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/client';

import { fetchJobTitles, dollarsToCents } from '../../../utils/helpers';
import SignersCard from '../../../macro_components/SignersCard/SignerCard';
import { SignerDetails } from '../../../macro_components/SignersCard/SignerCardTypes';
import { getAuthorizedSignerStructure } from '../order.utils';
import { GET_JOB_TITLES_FOR_TCV } from '../../../graphql/queries/order';
import { SIGNER_ENTITIES } from '../../../constants/common.constants';

interface SignersProps {
  disableEdit: boolean;
  isNewCustomer?: boolean;
  customerNumber: string;
  orderSigners: SignerDetails[];
  customerName: string;
  shouldShowOnlyAuthSigner: boolean;
  tcv: number;
}

function Signers({
  disableEdit,
  isNewCustomer = false,
  customerNumber,
  orderSigners,
  customerName,
  shouldShowOnlyAuthSigner,
  tcv,
}: SignersProps): JSX.Element {
  const [jobTitles, setJobTitles] = useState([]);
  const [signers, setSigners] = useState(orderSigners);
  const { values, setFieldValue } = useFormikContext();

  const [fetchTcvJobTitles, { data: tcvJobsPayload }] = useLazyQuery(
    GET_JOB_TITLES_FOR_TCV,
    {
      variables: {
        customerNumber,
        amountCents: dollarsToCents(tcv),
      },
    }
  );

  // Fetch job titles
  useEffect(() => {
    const titles = JSON.parse(sessionStorage.getItem('job-titles') || '[]');

    if (isEmpty(titles)) {
      fetchJobTitles()
        .then((response) => {
          setJobTitles(response.titles);
          sessionStorage.setItem('job-titles', JSON.stringify(response.titles));
        })
        .catch((error) => reportError(error.message));
    } else {
      setJobTitles(titles);
    }

    const hasGuarantor = orderSigners?.some(
      (signer) => signer.signatureEntity === SIGNER_ENTITIES.CORPORATE_GUARANTOR
    );
    // only fetch tcv is signers array has guarantor entity
    if (hasGuarantor) fetchTcvJobTitles();

    // this useEffect should only run on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldShowOnlyAuthSigner) {
      // set the signers array to the authorized signer structure
      const prevSigners = get(values, 'signers.0', {});
      const authorizedSigner = getAuthorizedSignerStructure(customerName);

      setSigners([authorizedSigner]);
      setFieldValue('signers', [{ ...prevSigners, ...authorizedSigner }]);
    }
    // add eslint disable to use this useEffect as subscription-based side effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName, shouldShowOnlyAuthSigner, setFieldValue]);

  const tcvJobTitles = useMemo(() => {
    if (tcvJobsPayload) {
      // tcvJobTitles should be an array of dropdown options with label and value
      return tcvJobsPayload?.authorizedJobTitlesForTcv?.map((job) => ({
        label: job,
        value: job,
      }));
    }
    return [];
  }, [tcvJobsPayload]);

  return (
    <div className="grid grid-cols w-full gap-4">
      {signers?.map((signerDetails, index) => (
        <SignersCard
          signerIndex={index}
          isNewCustomer={isNewCustomer}
          signerDetails={signerDetails}
          customerNumber={customerNumber}
          disableEdit={disableEdit || !!signerDetails.isPgUser}
          jobTitles={jobTitles}
          tcvJobTitles={tcvJobTitles}
        />
      ))}
    </div>
  );
}

export default Signers;
