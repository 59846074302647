import { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Card } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';
import { get, omit, debounce } from 'lodash';
import {
  SIGNER_FIELDS_TO_OMIT,
  SIGNER_JOB_TITLE_VALIDATION_DEBOUNCE_TIME,
} from '../../pages/Orders/order.constants';
import { getIsAuthorizedSigner } from '../../pages/Orders/order.utils';
import FreeTextDropdownInput from '../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';
import PatternInput from '../../designSystem/PatternInput/PatternInput';
import TextAreaInput from '../../designSystem/TextAreaInput/TextAreaInput';
import AuthUserSearchField from '../../components/AuthUserSearchField/AuthUserSearchField';
import {
  PHONE_NUMBER_EMPTY,
  PHONE_NUMBER_FORMAT,
  PHONE_NUMBER_PLACEHOLDER,
  SIGNER_ENTITIES,
  SIGNER_MESSAGE_PLACEHOLDER,
} from '../../constants/common.constants';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import { SignersCardProps } from './SignerCardTypes';
import NewCustomerAddress from '../../pages/Orders/NewCustomerAddress/NewCustomerAddress';
import { ReactComponent as DownArrow } from '../../assets/arrow_down_blue.svg';
import { TEXT_CONSTANTS } from '../../constants/ui/text.constants';

interface FieldMetaType {
  value: string;
  error: string;
  touched: boolean;
}

function SignersCard({
  signerIndex,
  signerDetails,
  isNewCustomer,
  customerNumber,
  disableEdit,
  jobTitles,
  tcvJobTitles,
}: SignersCardProps): JSX.Element {
  const formikBag = useFormikContext();
  const isBuyerSigner = signerDetails.signatureEntity === SIGNER_ENTITIES.BUYER;
  const isAuthorizedSigner = getIsAuthorizedSigner(signerDetails);
  const [hasJobTitleWarning, setHasJobTitleWarning] = useState(false);

  // show customer address form only for first buyer signer
  const shouldRenderCustomerAddressForm = isNewCustomer && isAuthorizedSigner;

  // show message field only for first buyer signer
  const shouldRenderMessageField = isAuthorizedSigner;

  // show search and select only for buyer signer
  const shouldShowSearchAndSelect = isBuyerSigner;

  // disable form edit for buyer signer when pg required
  const shouldDisableFormEdit = disableEdit && isBuyerSigner;

  // get field name for signer based on signer index
  const getSignerFieldName = useCallback(
    (fieldName: string) => `signers.${signerIndex}.${fieldName}`,
    [signerIndex]
  );

  const setContactValues = async (formattedContact): Promise<void> => {
    const { title, ...rest } = formattedContact;
    const fieldNamesPrefix = `signers.${signerIndex}`;
    const signerValues = get(formikBag.values, `signers.${signerIndex}`, {});
    const updatedValues = {
      ...signerValues,
      ...rest,
      ...((title || title === '') && { jobTitle: title }),
    };
    await formikBag.setFieldValue(
      fieldNamesPrefix,
      omit(updatedValues, SIGNER_FIELDS_TO_OMIT)
    );
    await formikBag.validateForm(); // Set the field value without title
  };

  const signerFirstName = useMemo(() => {
    return get(formikBag?.values, `signers.${signerIndex}.firstName`, '');
  }, [formikBag?.values, signerIndex]);

  const signerPhone = useMemo(() => {
    return get(formikBag?.values, `signers.${signerIndex}.phone`, '');
  }, [formikBag?.values, signerIndex]);

  const jobTitleMeta = useMemo(() => {
    return formikBag.getFieldMeta(getSignerFieldName('jobTitle')) as FieldMetaType;
  }, [formikBag, getSignerFieldName]);

  const isCorporateGuarantorSigner = useMemo(
    () => signerDetails.signatureEntity === SIGNER_ENTITIES.CORPORATE_GUARANTOR,
    [signerDetails]
  );

  const validateJobTitle = useCallback(() => {
    const selectedJobTitleError = get(jobTitleMeta, 'error', '');
    const isInvalidJobTitle = !tcvJobTitles.some(
      (job) => job?.value?.toLowerCase() === jobTitleMeta?.value?.toLowerCase()
    );
    // if the selected job title is invalid, set a warning that's displayed next to job titles field
    const shouldSetWarning =
      isCorporateGuarantorSigner && // only show warning for guarantor signers
      isInvalidJobTitle && // only show warning if the selected job title does not exist in supported jobs
      !hasJobTitleWarning && // only show warning if it's not already set
      !selectedJobTitleError && // only show warning if there's no field validation error to avoid duplicate warnings
      jobTitleMeta?.touched && // only show warning if the field has been touched
      !formikBag.isSubmitting; // warning triggers should be avoided during form submissions

    // if its a guarantor signer and invalid job is selected, return relevant error
    if (shouldSetWarning) {
      setHasJobTitleWarning(true);
    }
    // otherwise if there was an error and the value isnt invalid anymore, we can remove the error
    else if (hasJobTitleWarning && !isInvalidJobTitle) {
      setHasJobTitleWarning(false);
    }
  }, [
    isCorporateGuarantorSigner,
    jobTitleMeta,
    tcvJobTitles,
    hasJobTitleWarning,
    setHasJobTitleWarning,
    formikBag.isSubmitting,
  ]);

  const debouncedValidateJobTitle = useMemo(
    () => debounce(validateJobTitle, SIGNER_JOB_TITLE_VALIDATION_DEBOUNCE_TIME),
    [validateJobTitle]
  );

  useEffect(() => {
    debouncedValidateJobTitle();
  }, [jobTitleMeta, tcvJobTitles, debouncedValidateJobTitle]);

  useEffect(() => {
    // set email field as touched for guarantor or incumbency signers to show email errors based on other field changes
    const emailField = getSignerFieldName('email');
    const emailMeta = formikBag.getFieldMeta(emailField);
    const shouldMarkEmailTouched =
      !isAuthorizedSigner && !emailMeta?.touched && emailMeta?.value;
    if (shouldMarkEmailTouched) {
      formikBag.setFieldTouched(emailField, true);
    }
  }, [getSignerFieldName, isAuthorizedSigner, formikBag, signerIndex]);

  const phoneFieldCustomClassName =
    !signerPhone || signerPhone === PHONE_NUMBER_EMPTY
      ? 'pattern-field-container-empty'
      : 'pattern-field-container';

  return (
    <div className="signers-card">
      <Card
        variant="fullWidth"
        content={(
          <>
            {shouldRenderCustomerAddressForm && <NewCustomerAddress />}
            <div className="flex gap-1 items-center pb-2">
              <Typography variant="heading18" bold>
                {signerDetails.label}
              </Typography>
            </div>
            <Typography variant="paragraph14" color="color-black-100" className="vp-body">
              {signerDetails.description}
            </Typography>

            <div className="flex flex-col pt-6">
              <div className="space-y-4">
                <div className="flex flex-row space-x-4">
                  {shouldShowSearchAndSelect ? (
                    <AuthUserSearchField
                      name={getSignerFieldName('firstName')}
                      label="First name"
                      onSelectOption={setContactValues}
                      value={signerFirstName}
                      companyNumber={customerNumber}
                      disabled={shouldDisableFormEdit}
                    />
                  ) : (
                    <TextInputField
                      {...{
                        name: getSignerFieldName('firstName'),
                        label: 'First name',
                        disabled: shouldDisableFormEdit,
                      }}
                      ref={null}
                    />
                  )}

                  <TextInputField
                    {...{
                      name: getSignerFieldName('lastName'),
                      label: 'Last name',
                      disabled: shouldDisableFormEdit,
                    }}
                    ref={null}
                  />
                </div>
                <div
                  className={`flex flex-row ${hasJobTitleWarning ? 'warning-field' : ''}`}
                >
                  <FreeTextDropdownInput
                    disabled={shouldDisableFormEdit}
                    id={getSignerFieldName('jobTitle')}
                    name={getSignerFieldName('jobTitle')}
                    label="Job title"
                    suffixIcon={shouldDisableFormEdit ? <></> : <DownArrow />} // Icon to display on the right
                    options={jobTitles}
                    resetOptionsOnBlur
                  />
                  {hasJobTitleWarning && (
                    <Typography variant="paragraph10" color="color-gold-160">
                      {TEXT_CONSTANTS.INVALID_JOB_TITLE_ERROR}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-row space-x-4">
                  <TextInputField
                    {...{
                      id: getSignerFieldName('email'),
                      name: getSignerFieldName('email'),
                      label: 'Email',
                      type: 'email',
                      placeholder: 'example@email.com',
                      disabled: shouldDisableFormEdit,
                    }}
                    ref={null}
                  />
                  <PatternInput
                    {...{
                      id: getSignerFieldName('phone'),
                      name: getSignerFieldName('phone'),
                      label: 'Phone',
                      mask: '_',
                      disableFullStoryRecording: true,
                      disabled: shouldDisableFormEdit,
                      value: signerPhone,
                      format: PHONE_NUMBER_FORMAT,
                      placeholder: PHONE_NUMBER_PLACEHOLDER,
                      customClassName: phoneFieldCustomClassName,
                    }}
                    ref={null}
                  />
                </div>
                {shouldRenderMessageField && (
                  <div className="flex flex-row space-x-4">
                    <TextAreaInput
                      id={getSignerFieldName('message')}
                      name={getSignerFieldName('message')}
                      label="Message for signer (optional)"
                      placeholder={SIGNER_MESSAGE_PLACEHOLDER}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
}

export default SignersCard;
