import React from 'react';
import { Typography } from '@vartanainc/design-system';
import maintenanceImage from '../../assets/maintenance.png';
import { ReactComponent as PartnershipWithVartana } from '../../assets/partnership_with_vartana.svg';

const Maintenance = () => {
  return (
    <div className="flex flex-col h-[100vh] gap-[5rem]">
      <div className="w-100 flex-grow flex justify-center">
        <div className="h-full flex flex-col w-full items-center justify-center max-w-[600px] gap-[3.5rem]">
          <img
            src={maintenanceImage}
            alt="maintenance"
            className="w-[90%] max-w-[382px]"
          />
          <div className="flex gap-4 flex-col">
            <Typography
              variant="heading24"
              className="text-center w-100 block"
              bold
              color="color-gray-140"
            >
              We&rsquo;ll be back soon!
            </Typography>
            <div className="flex flex-col">
              <Typography
                variant="paragraph14"
                className="max-w-[400px] text-center"
                color="color-gray-140"
              >
                Sorry for the inconvenience! We&rsquo;re performing maintenance to enhance
                your experience. Please check back soon.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end align-center px-24 py-6">
        <PartnershipWithVartana className="h-[1.5rem]" />
      </div>
    </div>
  );
};

export default Maintenance;
