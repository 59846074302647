import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { ErrorBoundary, useRollbar, useRollbarPerson } from '@rollbar/react';
import { useLazyQuery } from '@apollo/client';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';
import Modal from 'react-modal';
import { GET_SESSION_USER } from './graphql/queries/root';
import { useSetCookie } from './utils/hooks';

import App from './App';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import Launchpad from './pages/Launchpad/LaunchPad';
import TalkToUs from './pages/TalkToUs/TalkToUs';
import ToastNotification from './components/ToastNotification';
import IconLoader from './components/IconLoader/IconLoader';

import {
  sessionTypeVar,
  sessionVar,
  requestModelAmountVar,
  metabaseUrlVar,
} from './graphql/cache';
import { SELLER_TYPE, sessionTypes } from './constants/common.constants';
import { convertKeysToCamelCase, allowFullstory } from './utils/helpers';
import ErrorPage from './pages/Error';
import Transactions from './pages/Transactions';
import Insights from './pages/Insights';
import ResetPassword from './pages/LandingPageV2/ResetPassword';
import ForgotPassword from './pages/LandingPageV2/ForgotPassword';
import SignIn from './pages/LandingPageV2/SignIn';
import SignUp from './pages/LandingPageV2/SignUp';
import Maintenance from './pages/LandingPageV2/Maintenance';

import {
  getAllowedRoutesForDashboard,
  getAllowedRoutesForWidget,
} from './utils/routeUtils';
import { useOnboarding } from './utils/hooks/onboarding';
import Onboarding from './pages/Dashboard/MainBody/Onboarding';
import UserDetails from './pages/Dashboard/MainBody/Onboarding/UserDetails';
import VendorDetails from './pages/Dashboard/MainBody/Onboarding/VendorDetails';
import FinanceUserDetails from './pages/Dashboard/MainBody/Onboarding/FinanceUserDetails';
import Loader from './components/Loader';
import { navigationService } from './graphql/navigation.service';
import CalendlyInvite from './pages/Dashboard/MainBody/Onboarding/CalendlyInvite';
import AutoLoad from './components/AutoLoad';
import { combinedPermissionAllowed } from './utils/hooks/permissions';

Modal.setAppElement('#root');

// eslint-disable-next-line no-underscore-dangle
window._fs_cookie_domain = window.location.host;
if (allowFullstory)
  FullStory.init({
    orgId: '129FQB',
    devMode:
      process.env.REACT_APP_NODE_ENV === 'eksstaging' ||
      process.env.REACT_APP_NODE_ENV === 'staging' ||
      process.env.REACT_APP_NODE_ENV === 'qa' ||
      process.env.REACT_APP_NODE_ENV === 'preview' ||
      process.env.REACT_APP_NODE_ENV === 'development',
    recordOnlyThisIFrame: true, // allow fullstory session in iframes
    cookieDomain: window.location.host,
  });

function RootComponent() {
  const rollbar = useRollbar();
  const { show: showIntercom, boot: bootIntercom } = useIntercom();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [setCookie] = useSetCookie();

  const [cookieCompleted, setCookieCompleted] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [flowType, setFlowType] = useState(null);

  const [
    { openOnboarding, currentStepIndex, onboardingStepRoutes, vendorLoading, vendor },
    { goForward, goBack, toggleOnboarding },
  ] = useOnboarding(cookieCompleted);

  const useQueryParams = new URLSearchParams(search);
  const showIntercomWindow = useQueryParams.get('help') === 'true';
  const token = useQueryParams.get('t');
  const application = useQueryParams.get('application');
  const flow = useQueryParams.get('flow');

  // Initialize Intercom
  const initIntercomAndFullStory = useCallback(() => {
    const shouldBootIntercom =
      pathname.includes('/forms/') || pathname.includes('dashboard');
    if (currentUser && allowFullstory) {
      FullStory.identify(currentUser.email, {
        customer: currentUser.fullName,
        displayName: currentUser.fullName,
        email: currentUser.email,
      });
      if (shouldBootIntercom) {
        bootIntercom({
          name: application === 'hubspot' ? '' : currentUser.fullName,
          email: currentUser.email,
          created_at: new Date(currentUser.createdAt).valueOf(),
          customAttributes: {
            application: 'widget-app',
            environment: process.env.REACT_APP_NODE_ENV,
            resourceId: currentUser.id,
            resourceType: 'User',
          },
        });
        if (showIntercomWindow) showIntercom();
      }
    }
  }, [
    pathname,
    currentUser,
    showIntercomWindow,
    showIntercom,
    bootIntercom,
    application,
  ]);

  const [getSessionData, { data: sessionData, loading: sessionLoading }] = useLazyQuery(
    GET_SESSION_USER,
    {
      onCompleted: () => {
        const maintenancePage = '/maintenance';
        const allowUserSession =
          !!sessionData?.session.isHijacked ||
          !!sessionData?.session.user?.company?.enabledForBrokering;
        const isAtMaintenance = window.location.pathname.includes(maintenancePage);
        if (!allowUserSession && !isAtMaintenance) {
          navigate(maintenancePage);
          return;
        }
        sessionVar(sessionData);
        requestModelAmountVar(
          get(sessionData, 'session.user.company.product.useRequestedAmount', false)
        );
        metabaseUrlVar({
          url: get(sessionData, 'session.user.company.metabaseUrl', ''),
          flag: get(sessionData, 'session.user.company.showMetabaseDashboard'),
        });
        sessionTypeVar(
          sessionData?.session?.isHijacked
            ? sessionTypes.ADMIN_USER
            : sessionTypes.VENDOR_USER
        );
        setPermissions(
          convertKeysToCamelCase(
            get(sessionData, 'session.user.permissionSet.role_permissions', {})
          )
        );
        setCurrentUser(get(sessionData, 'session.user', {}));
        initIntercomAndFullStory();
      },
    }
  );

  const sellerType = get(sessionData, 'session.user.company.sellerType');
  const isPureReseller = sellerType === SELLER_TYPE.RESELLER;
  const globalResellerPermissions = convertKeysToCamelCase(
    get(sessionData, 'globalResellerPermissions', {})
  );

  useRollbarPerson({
    id: currentUser?.id,
    email: currentUser?.email,
    username: currentUser?.email,
    name: currentUser?.fullName,
    userRole: currentUser?.userRole?.name,
    company: {
      number: currentUser?.company?.number,
      name: currentUser?.company?.name,
    },
  });

  // Set cookie & initialize session data
  useEffect(() => {
    async function onMount() {
      if (allowFullstory) FullStory.getCurrentSessionURL();
      if (token)
        await setCookie(token)
          .then((response) => {
            setCookieCompleted(true);
            if (!response.success) rollbar.info('Token invalid');
          })
          .catch((error) => {
            setCookieCompleted(true);
            rollbar.error(`Authentication failure with given token: ${error.message}`);
          });
      else setCookieCompleted(true);

      try {
        await getSessionData();
      } catch (e) {
        rollbar.error(`[GET_SESSION_USER]: ${e}`);
      }
    }
    onMount();
  }, [token, getSessionData, setCookie, rollbar]);

  // Identifying if popup for redirect purpose
  useEffect(() => {
    if (window.opener) {
      const popupQueryParams = new URLSearchParams(window.location.href);
      const isSso = popupQueryParams.get('sso_route');
      if (isSso) {
        window.opener.addEventListener(
          'beforeunload',
          window.postMessage(
            {
              type: 'login-status-closed',
              data: { windowClosed: true },
            },
            '*'
          )
        );
        if (window.location.href.includes('error')) {
          window.opener.postMessage(
            {
              type: 'login-status-error',
              data: { redirectUrl: window.location.href },
            },
            '*'
          );
          window.close();
        } else if (window.location.href.includes(window.location.host)) {
          window.opener.postMessage(
            {
              type: 'login-status-redirected',
              data: { redirectUrl: window.location.href },
            },
            '*'
          );
          window.close();
        }
      }
    }
  }, []);

  const ldClient = useLDClient();
  const companyNumber = get(sessionData, 'session.user.company.number', '');

  // Initialize LaunchDarkly client, or close in case of local env
  useEffect(() => {
    if (!isEmpty(companyNumber)) {
      if (process.env.REACT_APP_NODE_ENV !== 'development') {
        const ldContext = {
          key: companyNumber,
          kind: 'company',
        };
        ldClient.identify(ldContext, undefined);
      } else {
        ldClient.close();
      }
    }
  }, [ldClient, companyNumber]);

  useEffect(() => {
    if (!flowType) setFlowType(flow);
  }, [flow, flowType]);

  const userHasPermission = useCallback(
    (resource, action) => {
      return combinedPermissionAllowed(
        permissions,
        globalResellerPermissions,
        sellerType,
        resource,
        action
      );
    },
    [permissions, globalResellerPermissions, sellerType]
  );

  const dashboardRoutes = useMemo(
    () =>
      getAllowedRoutesForDashboard(userHasPermission, isEmpty(permissions), sessionData),
    [permissions, sessionData, userHasPermission]
  );

  const widgetRoutes = useMemo(
    () =>
      getAllowedRoutesForWidget(
        userHasPermission,
        get(sessionData, 'session.user.company', {}),
        isEmpty(permissions) || !cookieCompleted
      ),
    [cookieCompleted, permissions, sessionData, userHasPermission]
  );

  const firstOnboardingPage = useMemo(() => {
    if (onboardingStepRoutes.length === 0) return <Loader className="w-12 h-12" />;

    return <Navigate to={onboardingStepRoutes[currentStepIndex]} />;
  }, [currentStepIndex, onboardingStepRoutes]);

  const finishOnboarding = useCallback(() => {
    getSessionData();
    toggleOnboarding(false);
    navigate('launchpad');
  }, [getSessionData, navigate, toggleOnboarding]);

  useEffect(() => {
    navigationService.navigate = navigate;
    navigationService.rollbar = rollbar;
  }, [navigate, rollbar]);

  useEffect(() => {
    const currentHostname = window.location.href;
    if (
      !currentHostname.includes('.com') &&
      process.env.REACT_APP_NODE_ENV !== 'development'
    )
      window.location.href = currentHostname.replace('.co', '.com');
  }, [navigate, sessionData]);

  const firstElement = useMemo(
    () => (
      <AutoLoad loading={isPureReseller === undefined}>
        <Navigate to={isPureReseller ? '/dashboard/vendors' : '/dashboard'} replace />
      </AutoLoad>
    ),
    [isPureReseller]
  );

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content="
            base-uri 'self';
            script-src 'self' 'unsafe-inline' 'unsafe-eval' https://cdn.plaid.com/link/v2/stable/link-initialize.js https://maps.googleapis.com https://maps.gstatic.com https://app.intercom.io https://js.intercomcdn.com https://widget.intercom.io https://edge.fullstory.com/s/fs.js;
            frame-src data: https://cdn.plaid.com/ https://embedded.tray.io/ https://app.hellosign.com/ https://analytics.vartana.com/ https://calendly.com/ https://apps-d.docusign.com/ https://apps.docusign.com/;
            form-action 'self';
            upgrade-insecure-requests;"
        />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Helmet>
      <ErrorBoundary fallbackUI={ErrorPage}>
        <ToastNotification />
        <IconLoader />
        <Routes>
          <Route
            path="/"
            element={(
              <App
                vendor={vendor}
                userRole={get(sessionData, 'session.user.userRole', {})}
                rolePermissions={permissions}
                globalResellerPermissions={globalResellerPermissions}
                setPermissions={setPermissions}
                loading={sessionLoading || vendorLoading}
              />
            )}
          >
            <Route index element={firstElement} />

            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/login" element={<LandingPage />}>
              <Route path="" element={<SignIn />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route
                path="activate-account"
                element={<ResetPassword mode="activate" />}
              />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="/signup" element={<SignUp />} />

            <Route
              path="/launchpad"
              element={<Launchpad openOnboarding={openOnboarding} />}
            />

            <Route path="/help" element={<TalkToUs />} />

            <Route
              path="/dashboard"
              element={<Dashboard openOnboarding={openOnboarding} />}
            >
              <Route
                path=""
                element={
                  isPureReseller ? (
                    <Navigate to="vendors" replace />
                  ) : (
                    <Navigate to="/launchpad" replace />
                  )
                }
              />
              <Route path="analytics" element={<Insights />} />
              {dashboardRoutes}
              <Route path="transactions" element={<Transactions />} />
              <Route path="calendly" element={<CalendlyInvite onClose={() => {}} />} />
            </Route>
            {widgetRoutes}

            {/* Error routes */}
            <Route
              path="/something-went-wrong"
              element={<ErrorPage errorTitle="Something went wrong" />}
            />
            <Route
              path="/error"
              element={<ErrorPage errorTitle="Something went wrong" />}
            />
            <Route
              path="/access-denied"
              element={(
                <ErrorPage
                  errorTitle="You may not have access to this page"
                  errorMessage="Please contact your super admin to find out if you have access permissions"
                />
              )}
            />
            <Route path="*" element={<ErrorPage errorTitle="Page not found" />} />
          </Route>

          {/* Onboarding Routes */}
          <Route
            path="onboarding"
            element={(
              <Onboarding
                open={openOnboarding}
                currentStepIndex={currentStepIndex}
                loading={vendorLoading}
                goForward={goForward}
                goBack={goBack}
                currentStep={onboardingStepRoutes[currentStepIndex] || ''}
                onClose={finishOnboarding}
                isPureReseller={isPureReseller}
                isOnboarding={flowType === 'onboarding'}
              />
            )}
          >
            <Route index element={firstOnboardingPage} />
            <Route path="user-details" element={<UserDetails />} />
            <Route path="vendor-details" element={<VendorDetails />} />
            <Route path="finance-user-details" element={<FinanceUserDetails />} />
            <Route
              path="calendly"
              element={<CalendlyInvite onClose={finishOnboarding} />}
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default RootComponent;
