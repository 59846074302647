/* eslint-disable import/prefer-default-export */

import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { useApolloClient, useMutation } from '@apollo/client';

import {
  CREATE_DIRECT_UPLOADS,
  ATTACH_BLOBS_RESOURCE,
  ATTACH_SALES_QUOTE_RESOURCE,
  ATTACH_BLOBS_CUSTOMER,
  REPLACE_DOCUMENT_BLOBS_RESOURCE,
  ATTACH_ORDER_PROPOSAL_BLOB,
  ATTACH_PROPOSAL_VENDOR_DOCS,
} from '../../graphql/queries/directUpload';
import { calculateFilesMetadata } from '../helpers';
import { performUpload } from '../../api/directUpload';
import { fetchCookie } from '../../api/token';
import { UPDATE_FINANCIALS } from '../../graphql/queries/creditCheck';
import { MIN_ZIP_LENGTH } from '../../constants/common.constants';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useSetCookie = () => {
  const [loading, setLoading] = useState(false);

  const resolver = useCallback(
    (token) => {
      return new Promise((resolve, reject) => {
        setLoading(true);
        fetchCookie(token)
          .then((response) => {
            setLoading(false);
            resolve(response.json());
          })
          .catch((error) => {
            setLoading(false);
            reject(error);
          });
      });
    },
    [setLoading]
  );

  return [resolver, loading];
};

export const useClientRect = () => {
  const [rect, setRect] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
};

export const useDirectUploadFilesCustomer = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobs] = useMutation(ATTACH_BLOBS_CUSTOMER);

  const [isLoading, setIsLoading] = useState(false);

  return [
    isLoading,
    function directUploadFiles(files, attachBlobData) {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const attachBlobsInput = merged.map((file) => ({
                      ...attachBlobData,
                      blobId: file.metadata.blobId,
                    }));
                    attachBlobs({
                      variables: {
                        attachBlobs: attachBlobsInput,
                      },
                    })
                      .then(() => {
                        setIsLoading(false);
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
  ];
};

export const useDirectUploadFiles = () => {
  const [createDirectUploads, { loading: uploading }] =
    useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobsResource, { loading: attachingBlobs }] =
    useMutation(ATTACH_BLOBS_RESOURCE);

  return [
    function directUploadFiles(files, documentBlobs, resource) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const documentBlobsInput = merged.map((file) => ({
                      ...documentBlobs,
                      blobId: file.metadata.blobId,
                    }));
                    attachBlobsResource({
                      variables: {
                        resourceId: resource.id,
                        resourceType: resource.type,
                        milestoneNumber: resource.milestoneNumber,
                        documentBlobs: documentBlobsInput,
                      },
                    })
                      .then(() => {
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
    { loading: uploading || attachingBlobs },
  ];
};

export const UploadSalesQuote = () => {
  const [createDirectUploads, { loading: uploading }] =
    useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobsResource, { loading: attachingBlobs }] = useMutation(ATTACH_SALES_QUOTE_RESOURCE);

  return [
    function directUploadFiles(files, documentBlobs, resource) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const documentBlobsInput = merged.map((file) => ({
                      ...documentBlobs,
                      blobId: file.metadata.blobId,
                    }));
                    attachBlobsResource({
                      variables: {
                        creditCheckId: resource.id,
                        documentBlobs: documentBlobsInput,
                      },
                    })
                      .then(() => {
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
    { loading: uploading || attachingBlobs },
  ];
};

export const useAttachFinancialDocs = () => {
  const [createDirectUploads, { loading: uploading }] =
    useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobsResource, { loading: attachingBlobs }] =
    useMutation(UPDATE_FINANCIALS);

  return [
    function directUploadFiles(files, fileMeta, customerNumber) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const documentBlobs = [];
                    merged.forEach((file, index) => {
                      documentBlobs.push({
                        documentType: fileMeta.metaArray[index]?.documentType,
                        externalId: fileMeta.metaArray[index]?.externalId,
                        blobId: file.metadata.blobId,
                      });
                    });
                    attachBlobsResource({
                      variables: {
                        customerNumber,
                        documentBlobs,
                        note: fileMeta.notes,
                      },
                    })
                      .then(() => {
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
    { loading: uploading || attachingBlobs },
  ];
};

export const useUploadOrderProposalFile = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [attachOrderProposalBlob] = useMutation(ATTACH_ORDER_PROPOSAL_BLOB);

  return [
    function directUploadOrderProposalFile(file, fileUuid) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata([...file])
          .then((fileMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: fileMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                performUpload(
                  ...file,
                  JSON.parse(directUploads[0].headers),
                  directUploads[0].url
                )
                  .then(() => {
                    attachOrderProposalBlob({
                      variables: {
                        blobId: directUploads[0].blobId,
                        uuid: fileUuid,
                      },
                    })
                      .then(() => {
                        resolve();
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
  ];
};

export const useUploadProposalVendorDocs = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [attachProposalVendorDocs] = useMutation(ATTACH_PROPOSAL_VENDOR_DOCS);

  return [
    function directUploadVendorProposalDocs(files, fileMeta) {
      // convert this to a files array
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files) // upload all the files and check their return order so that respected fileUuid can be retained
          .then((fileMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: fileMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                let uploadFiles = [];
                uploadFiles = files.map((file, index) => {
                  return performUpload(
                    file,
                    JSON.parse(directUploads[index].headers),
                    directUploads[index].url
                  );
                });
                Promise.all(uploadFiles)
                  .then(() => {
                    const documentBlobs = directUploads.map((directUpload, index) => ({
                      blobId: directUpload?.blobId,
                      uuid: fileMeta[index],
                    }));
                    attachProposalVendorDocs({
                      variables: {
                        documentBlobs,
                      },
                    })
                      .then(() => {
                        resolve();
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
  ];
};

export const useDirectUploadReplaceDocumentBlobs = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [replaceDocumentBlobsResource] = useMutation(REPLACE_DOCUMENT_BLOBS_RESOURCE);

  return [
    function directUploadReplaceDocumentBlobs(files, documentBlobs, resource) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const documentBlobsReplaceInput = merged.map((file) => ({
                      ...documentBlobs,
                      blobId: file.metadata.blobId,
                    }));
                    replaceDocumentBlobsResource({
                      variables: {
                        resourceId: resource.id,
                        resourceType: resource.type,
                        documentReplaceBlobs: documentBlobsReplaceInput,
                      },
                    })
                      .then(() => {
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
  ];
};

export function useLazyQuery(query) {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);

  const setLoaders = useCallback(() => {
    if (!called) {
      setLoading(true);
    }
    setCalled(true);
  }, [setCalled, setLoading, called]);

  const resetLoaders = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const executer = useCallback(
    (variables) => {
      return new Promise((resolve, reject) => {
        setLoaders();
        return client
          .query({
            query,
            variables,
          })
          .then((response) => {
            resetLoaders();
            resolve(response);
          })
          .catch((error) => {
            resetLoaders();
            reject(error);
          });
      });
    },
    [query, client, resetLoaders, setLoaders]
  );

  return {
    loading,
    executer,
  };
}

export const useMountEffect = (fun) => useEffect(fun, []); // eslint-disable-line react-hooks/exhaustive-deps

/**
 * Custom hook to set address fields in a Formik form.
 * Updates form fields based on selected place data (city, state, zip, country).
 * Includes validation for zip code length, and optional country field support.
 *
 * @param {Function} setFieldValue - Formik's setFieldValue to update form values.
 * @param {Function} validateField - Formik's validateField to validate updated fields.
 * @param {boolean} hasCountry - Flag to determine if country field should be updated.
 * @param {string} fieldName - Optional prefix for field names.
 * @returns {Function} setSelectedPlace - Function to set selected place data.
 */
export const useSetAddressFields = (
  setFieldValue,
  validateField,
  hasCountry = false,
  fieldName = ''
) => {
  const [selectedPlace, setSelectedPlace] = useState({});

  useEffect(() => {
    // Define field names with prefix if provided
    const cityFormField = `${fieldName}city`;
    const stateFormField = `${fieldName}state`;
    const zipFormField = `${fieldName}zip`;
    const countryFormField = `${fieldName}country`;

    // Validate zip code length for optional skipping
    const isZipCodeValid =
      selectedPlace.zip && selectedPlace.zip.length >= MIN_ZIP_LENGTH;
    const shouldSkipZipCode = hasCountry && !isZipCodeValid;

    // Update city field
    if (selectedPlace.city) {
      // TODO: MuhammadAhmadEjaz add a helper here for setFieldValue.
      setFieldValue(cityFormField, selectedPlace.city).then(() =>
        validateField(cityFormField)
      );
    } else {
      setFieldValue(cityFormField, '').then(() => validateField(cityFormField));
    }

    // Update state field
    if (selectedPlace.state?.shortName) {
      setFieldValue(stateFormField, selectedPlace.state.shortName).then(() =>
        validateField(stateFormField)
      );
    }

    // Update or clear zip code field based on validity
    if (shouldSkipZipCode) {
      setFieldValue(zipFormField, '').then(() => validateField(zipFormField));
    } else if (selectedPlace.zip) {
      setFieldValue(zipFormField, selectedPlace.zip).then(() =>
        validateField(zipFormField)
      );
    }

    // Update country field if available
    if (hasCountry && selectedPlace?.country?.shortName) {
      setFieldValue(countryFormField, selectedPlace.country.shortName).then(() =>
        validateField(countryFormField)
      );
    }
  }, [selectedPlace, setFieldValue, validateField, hasCountry, fieldName]);

  return setSelectedPlace;
};
