import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { debounce, get } from 'lodash';
import {
  AlertBox,
  Button,
  ButtonV2,
  InputField,
  InputFieldMoneyFormat,
  Typography,
} from '@vartanainc/design-system';
import { useMutation } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';

import { useFormikContext } from 'formik';
import SvgIcon from '../../components/SvgIcon/SvgIcon';
import { getCrmDocumentsContent, getCustomerCrmFileUrls } from '../../api/utils';
import { CREATE_CREDIT_CHECK_CHANGE_REQUEST } from '../../graphql/queries/customer';
import { ReactComponent as ThreeDotsPlaceholder } from '../../assets/three-dots.svg';
import MoneyInputField from '../../designSystem/MoneyInput/MoneyInputField';
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import {
  CustomScheduleFields,
  FileType,
  OrderContractLengthProps,
  OrderCustomerType,
  OrderFormValues,
  ProposalErrorsType,
} from './OrderTypes';
import {
  centsToDollars,
  computeClosestTerm,
  parseFloatWithTrailingZeros,
} from './order.utils';
import SubsidyField from '../../designSystem/CompactFields/SubsidyField';
import TermsDropdown from '../../designSystem/CompactFields/TermsDropdown';
import MultiFileUpload from '../../components/MultiFileUpload';
import {
  orderType,
  orderTypeMapping,
  paymentFrequencyLabels,
  proposalFields,
  oneWholeTwoDecimal,
  TOAST_SUCCESS_STATUS,
  CHANGE_REQUEST_SUCCESS_MESSAGE,
  ORDER_PAY_IN_FULL_VALUE,
  SPIFF_MODE,
  CONFIRMATION_SCREEN_VARIANTS,
  DUPLICATE_FILE_ALERT,
  commonRegex,
} from '../../constants/common.constants';
import {
  checkValueExists,
  convertBase64ToFile,
  createFileFromBlobUrl,
  getPageUrl,
  isWidget,
  showToast,
} from '../../utils/helpers';
import {
  MAX_ALLOWED_TCV,
  MAX_INTEREST_RATE_OPTIONS_FOR_RADIO_MODE,
  orderScreenRegex,
  TCV_BELOW_APPROVED_ERROR,
} from './order.constants';
import PaymentOptionSelect from '../../components/PaymentOptionSelect/PaymentOptionSelect';
import {
  ApprovedCreditTerm,
  NetTermsDropdownOptions,
  StringDropdownOptions,
  DropdownOption,
} from '../../utils/commonInterfaces';
import UploadDocsFromCrm from '../../components/Modals/UploadDocsFromCrm';
import InterestRateSelect from '../../components/InterestRateSelect/InterestRateSelect';

const CRM_ORDER_PATH = '/forms/order';
const MODIFICATION_REASON = 'Change request from Order form';

interface DropdownOptions {
  value: number;
  label: string;
}

interface ProposalFormProps {
  financeInfoLoading: boolean;
  proposalErrors: ProposalErrorsType[];
  formValues: OrderFormValues;
  index: number;
  selectedCustomer: OrderCustomerType;
  customerNumber: string;
  paymentOptions: { label: string; value: string; isDisabled: boolean }[];
  hideTermDropdown: boolean;
  setHideTermDropdown: (hideTermDropdown: boolean) => void;
  setFieldValue: (field: string, value) => void;
  spiffMode: string;
  subsidyAllowedOnProduct: boolean;
  setCreatingChangeRequest: (creatingChangeRequest: boolean) => void;
  currencySymbol: string;
  isSyndicated: boolean;
  isDemoVendor: boolean;
  isCustomSchedule: boolean;
  customScheduleFields: CustomScheduleFields;
  deferApprovedNetTerms: NetTermsDropdownOptions[];
  installmentApprovedCreditTerms: ApprovedCreditTerm;
  pullCrmData: boolean;
  enableOrderFormLoading: () => void;
  disableOrderFormLoading: () => void;
  buyerInterestRate: string | null;
  isEditOrder: boolean;
  applyBuyerInterestRate: boolean;
  buyerInterestRates: DropdownOption[];
}

const ProposalForm = ({
  financeInfoLoading,
  proposalErrors,
  formValues,
  index,
  selectedCustomer,
  customerNumber,
  paymentOptions,
  hideTermDropdown,
  setHideTermDropdown,
  setFieldValue,
  spiffMode,
  subsidyAllowedOnProduct,
  setCreatingChangeRequest,
  currencySymbol,
  isSyndicated,
  isDemoVendor,
  isCustomSchedule,
  customScheduleFields,
  deferApprovedNetTerms,
  installmentApprovedCreditTerms,
  pullCrmData,
  enableOrderFormLoading,
  disableOrderFormLoading,
  buyerInterestRate,
  isEditOrder,
  applyBuyerInterestRate,
  buyerInterestRates,
}: ProposalFormProps): ReactElement => {
  const CRM_FETCH_ERROR_TYPES = {
    nothingFound: 'nothingFound',
    fetchUrlError: 'fetchUrlError',
    fetchFilesError: 'fetchFilesError',
    someFilesFailed: 'someFilesFailed',
  };
  const CRM_FETCH_ERROR_MESSAGES = {
    nothingFound:
      'No files detected from your CRM. Please upload the documents manually.',
    fetchUrlError: 'Something went wrong while loading files. Please try again.',
    fetchFilesError: 'Something went wrong while loading files. Please try again.',
    badRequest: 'Bad request',
    someFilesFailed: 'Something went wrong while fetching some files. Please try again.',
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [isCrmFilesModalOpen, setIsCrmFilesModalOpen] = useState<boolean>(false);
  const [isCrmFilesModalLoading, setIsCrmFilesModalLoading] = useState<boolean>(false);
  const [crmFileUrls, setCrmFileUrls] = useState<FileType[]>([]);
  const [crmFilesHasError, setCrmFilesHasError] = useState<boolean>(false);
  const [crmFilesErrorType, setCrmFilesErrorType] = useState<string>('');
  const [uploaderAlert, setUploaderAlert] = useState<string>('');
  const orderDocs = formValues.orderForm;

  const [netTermOptions, setNetTermOptions] = useState<DropdownOptions[]>([]);
  const [billingOptions, setBillingOptions] = useState<StringDropdownOptions[]>([]);

  const [searchParams] = useSearchParams();
  const pricingEngineError = get(proposalErrors, '[index].pricing_engine', '');
  const currentProposal = `proposals.${index}`;
  const currentProposalErrors = proposalErrors[index];
  const hasProposalErrors = proposalErrors?.length > 0;
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1465px)' });
  const showPricingEngineError =
    !financeInfoLoading && hasProposalErrors && !!pricingEngineError;
  const crmOpportunityId =
    searchParams.get('crmOpportunityId') || location.state?.crmOpportunityId || '';
  const shouldRenderUploadFromCrmButton = pullCrmData && isWidget;
  const { setFieldTouched } = useFormikContext();

  const showTCVErrors =
    !showPricingEngineError &&
    hasProposalErrors &&
    !pricingEngineError &&
    !!currentProposalErrors?.amount &&
    formValues.amount;

  const hasPricingEngineError = hasProposalErrors && !pricingEngineError;
  const showTCVMoneyInputFieldError =
    showPricingEngineError || (hasPricingEngineError && !!currentProposalErrors?.amount);

  const contractLengthOptions = useMemo(() => {
    if (installmentApprovedCreditTerms) {
      const termList = Object.keys(installmentApprovedCreditTerms).map(
        (contractLength) => ({
          label: `${contractLength} months`,
          value: parseInt(contractLength, 10),
        })
      );

      if (termList.length) {
        const shouldSetTermValue =
          !formValues.contractLength ||
          (formValues.contractLength &&
            !checkValueExists(termList, formValues.contractLength));
        if (shouldSetTermValue) {
          setFieldValue(`${currentProposal}.contractLength`, termList[0].value);
        }
      }
      return termList;
    }

    return [];
    // we want to update the contract length options only when the installmentApprovedCreditTerms changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentApprovedCreditTerms]);

  const DOLLAR_OPTION = { label: currencySymbol, value: '$' };
  const PERCENTAGE_OPTION = { label: '%', value: '%' };

  const shouldDisableTermField =
    (contractLengthOptions?.length === 1 && isSyndicated) || isCustomSchedule;
  const shouldDisableNetTerms =
    (netTermOptions?.length === 1 && isSyndicated) || isCustomSchedule;
  const shouldDisableBillingFrequency =
    (billingOptions?.length === 1 && isSyndicated) || isCustomSchedule;

  const shouldShowInterestRateInDropdown =
    buyerInterestRates?.length > MAX_INTEREST_RATE_OPTIONS_FOR_RADIO_MODE ||
    isSmallScreen ||
    isCustomSchedule;

  useEffect(() => {
    const shouldSetTermValue =
      shouldDisableTermField &&
      !!contractLengthOptions.length &&
      formValues.contractLength !== contractLengthOptions[0].value;
    if (shouldSetTermValue) {
      setFieldValue(`${currentProposal}.contractLength`, contractLengthOptions[0].value);
    }
    if (
      shouldDisableNetTerms &&
      !!netTermOptions.length &&
      formValues.paymentTerm !== netTermOptions[0].value
    ) {
      setFieldValue(`${currentProposal}.paymentTerm`, netTermOptions[0].value);
    }
    if (
      shouldDisableBillingFrequency &&
      !!billingOptions.length &&
      formValues.paymentFrequency !== billingOptions[0].value
    ) {
      setFieldValue(`${currentProposal}.paymentFrequency`, billingOptions[0].value);
    }
    // We want to update the form values only when one of the dependecies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractLengthOptions,
    currentProposal,
    formValues.contractLength,
    formValues.paymentFrequency,
    formValues.paymentTerm,
    netTermOptions,
    setFieldValue,
    shouldDisableBillingFrequency,
    shouldDisableNetTerms,
    shouldDisableTermField,
  ]);

  const [createCreditCheckChangeRequest] = useMutation(
    CREATE_CREDIT_CHECK_CHANGE_REQUEST
  );

  const handleSubsidyUnitChange = (): void => {
    let subsidy = parseFloatWithTrailingZeros(formValues.subsidy);
    const { isDollar, amount: totalContractFormValue } = formValues;
    const totalContractValue = parseFloatWithTrailingZeros(totalContractFormValue);

    if (totalContractValue) {
      // if converting to percentage then convert subsidy value to percentage as well
      if (isDollar) {
        subsidy = parseFloatWithTrailingZeros(
          ((subsidy / totalContractValue) * 100).toFixed(2)
        );
      }
      // if converting to value then convert subsidy from percentage back to value
      else {
        subsidy = parseFloatWithTrailingZeros(
          ((subsidy / 100) * totalContractValue).toFixed(2)
        );
      }
    }

    setFieldValue(`${currentProposal}.subsidy`, Math.round(subsidy));
    setFieldValue(
      `${currentProposal}.maxSubsidyAllowed`,
      formValues.isDollar ? formValues.maxSubsidyPercentage : formValues.dollarMaxSubsidy
    );
    setFieldValue(`${currentProposal}.isDollar`, !formValues.isDollar);
  };

  const handleCreateChangeRequest = async (): Promise<void> => {
    setCreatingChangeRequest(true);
    const creditChangeRequestPayload = {
      totalContractAmount: formValues.amount,
      billingFrequency:
        formValues.orderType === orderType.pay_in_full
          ? ORDER_PAY_IN_FULL_VALUE
          : formValues.paymentFrequency,
      modificationReason: MODIFICATION_REASON,
      paymentTerm: formValues.paymentTerm,
      term:
        formValues.orderType === orderType.full_payment
          ? 0
          : parseFloat(formValues.contractLength as unknown as string),
      companyNumber: customerNumber,
      paymentOption: formValues.orderType,
    };
    const responseData = await createCreditCheckChangeRequest({
      variables: creditChangeRequestPayload,
    });
    setCreatingChangeRequest(false);
    const changeRequestCreated = !!responseData?.data?.createCreditCheckChangeRequest?.id;
    if (changeRequestCreated) {
      if (location.pathname.includes(CRM_ORDER_PATH)) {
        const confirmationPage = getPageUrl({ page: 'confirmation' });
        navigate(confirmationPage, {
          state: {
            customerName: get(selectedCustomer, 'name', ''),
            variant: CONFIRMATION_SCREEN_VARIANTS.CHANGE_REQUEST,
          },
        });
      } else {
        const customerPage = getPageUrl({ page: 'customerSummary', customerNumber });
        navigate(customerPage);
        setTimeout(() => {
          showToast(TOAST_SUCCESS_STATUS, CHANGE_REQUEST_SUCCESS_MESSAGE);
        }, 500);
      }
    }
  };

  const showField = (fieldType): boolean => {
    return formValues.orderType
      ? orderTypeMapping[formValues.orderType]?.includes(fieldType)
      : false;
  };

  const getMaxSubsidy = (): string => {
    return `${formValues.isDollar ? currencySymbol : ''}${formValues.maxSubsidyAllowed}${
      !formValues.isDollar ? '%' : ''
    }`;
  };

  const applyMaxSubsidy = (): void => {
    setFieldValue(
      `${currentProposal}.subsidy`,
      formValues.maxSubsidyAllowed?.replace(orderScreenRegex.percentOrDollar, '')
    );
  };

  // this is a list of values that are used in the proposal form's JSX below, this help's with the JSX readability
  const showChangeRequestButton =
    currentProposalErrors?.amount?.includes('value is above') ||
    currentProposalErrors?.amount?.includes(TCV_BELOW_APPROVED_ERROR);
  const showTermError = hasProposalErrors && !!currentProposalErrors?.term;
  const termError = hasProposalErrors && currentProposalErrors?.term;
  const maxAllowedSubsidy = formValues.isDollar ? (formValues.amount as number) : 100;
  const showProposalFields = formValues.orderType && formValues.orderType !== 'direct';

  const getFormattedInterestRateValue = useCallback((): string | null => {
    const formattedFetchedInterestRate = `${buyerInterestRate}`?.replace('%', '');
    if (formValues.interestRate === null) {
      if (isCustomSchedule) {
        return formattedFetchedInterestRate;
      }
      return null;
    }
    return formattedFetchedInterestRate;
  }, [formValues.interestRate, buyerInterestRate, isCustomSchedule]);

  const getInterestRateMatch = useCallback(() => {
    const formattedInterestRateValue = getFormattedInterestRateValue();

    const formInterestRateValue = formValues.interestRate || formattedInterestRateValue;
    const interestRateValue = buyerInterestRates?.find(
      (option) => parseFloat(`${formInterestRateValue}`) === parseFloat(`${option.value}`)
    );

    return interestRateValue;
  }, [buyerInterestRates, formValues.interestRate, getFormattedInterestRateValue]);

  const defaultInterestRateValue = useMemo(() => {
    if (buyerInterestRates?.length) {
      if (isEditOrder) {
        const interestRateValue = getInterestRateMatch();

        if (!interestRateValue) {
          setFieldValue(`${currentProposal}.interestRate`, null);
          return undefined;
        }
        setFieldValue(`${currentProposal}.interestRate`, interestRateValue?.value);
        return interestRateValue;
      }
      const matchedInterestRate = getInterestRateMatch();
      if (!matchedInterestRate) {
        setFieldValue(`${currentProposal}.interestRate`, null);
      }
      return matchedInterestRate;
    }
    return undefined;
  }, [
    buyerInterestRates?.length,
    isEditOrder,
    getInterestRateMatch,
    setFieldValue,
    currentProposal,
  ]);

  const shouldHideInterestRateForCustomSchedule =
    applyBuyerInterestRate && isCustomSchedule && !defaultInterestRateValue;

  const handleSubsidyKeyPress = debounce((e) => {
    setFieldValue(`${currentProposal}.interestRate`, null);
  }, 500);

  const shouldDisableInterestRateSelect =
    !formValues.amount || (isCustomSchedule && !defaultInterestRateValue);

  useEffect(() => {
    // This is done to display if error exists on Terms field for wrong combination selected
    setFieldTouched(`${currentProposal}.contractLength`, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Prepopulate the Order form fields with the custom schedule values
    const { isDollar } = formValues;
    if (isCustomSchedule) {
      setFieldValue(`${currentProposal}.amount`, customScheduleFields.tcv);
      setFieldValue(`${currentProposal}.spiffRate`, customScheduleFields.spiff);

      if (isDollar) {
        const amount = parseInt(customScheduleFields.blindDiscountInCents, 10);
        const subsidyValue = centsToDollars(amount);
        setFieldValue(`${currentProposal}.subsidy`, subsidyValue);
      } else {
        setFieldValue(`${currentProposal}.subsidy`, customScheduleFields.blindDiscount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomSchedule]);

  const handleCrmFilesError = (): void => {
    // setting crmFilesHasError to false to hide the error message
    // we need to call this function when the user interacts with any form field.
    if (crmFilesHasError) {
      setCrmFilesHasError(false);
    }
  };

  const handleApplyMaxSubsidy = (): void => {
    applyMaxSubsidy();
    handleCrmFilesError();
  };

  const renderTermsField = (): ReactElement => {
    if (isCustomSchedule) {
      return (
        /* We've used conditional rendering to render custom fields without effecting the existing ones */
        <InputField
          name={`${currentProposal}.paymentTerm`}
          id={`${currentProposal}.paymentTerm`}
          label="Net terms"
          value="Custom"
          disabled
          fullWidth
        />
      );
    }

    return (
      <CustomDropdown
        id={`${currentProposal}.paymentTerm`}
        name={`${currentProposal}.paymentTerm`}
        className="flex-1"
        label="Net terms"
        placeholder="Select option"
        disabled={shouldDisableNetTerms}
        options={
          netTermOptions &&
          netTermOptions.map((paymentTerm) => {
            return {
              label: paymentTerm.label,
              value: paymentTerm.value,
            };
          })
        }
        autoFocus={false}
        openMenuOnFocus={false}
        preFillFirstOption={false}
        isLoading={false}
        onClick={handleCrmFilesError}
      />
    );
  };

  // Function to get the keys of the Term and Frequency nested Net Terms object
  const filterNetTerms = (term: number, frequency: string): DropdownOptions[] | [] => {
    const closestTerm = computeClosestTerm(
      formValues.contractLength,
      installmentApprovedCreditTerms
    );
    const nestedData =
      installmentApprovedCreditTerms[closestTerm] ||
      installmentApprovedCreditTerms[`${closestTerm} (co-term)`];
    if (!nestedData || !nestedData[frequency]) {
      return [];
    }
    // Need conversion as netTerm itself is a number but DropDownOptions interface
    // takes option and value as string
    return nestedData[frequency].map((netTerm) => ({
      value: netTerm.value,
      label: netTerm.formatted,
    }));
  };

  const shouldRenderInterestRate = useMemo(() => {
    return (
      applyBuyerInterestRate &&
      !shouldHideInterestRateForCustomSchedule &&
      buyerInterestRates?.length > 0
    );
  }, [
    applyBuyerInterestRate,
    shouldHideInterestRateForCustomSchedule,
    buyerInterestRates,
  ]);

  useEffect(() => {
    if (formValues.orderType === orderType.pay_in_full) {
      const selectedTerms = deferApprovedNetTerms;
      const netTerms = selectedTerms?.map((netTerm) => ({
        value: netTerm.value,
        label: netTerm.formatted,
      }));
      setNetTermOptions(netTerms);
    } else if (formValues.contractLength && formValues.paymentFrequency) {
      setNetTermOptions(
        filterNetTerms(formValues.contractLength, formValues.paymentFrequency)
      );
    }
    // We want to update the net term options oupdated only when order type changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.orderType, deferApprovedNetTerms]);

  useEffect(() => {
    if (formValues.paymentFrequency && formValues.contractLength && !isCustomSchedule) {
      setNetTermOptions(
        filterNetTerms(formValues.contractLength, formValues.paymentFrequency)
      );
    }
    // We want to update the net term options updated only when payment frequency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.paymentFrequency]);

  useEffect(() => {
    if (
      formValues.contractLength &&
      !!Object.keys(installmentApprovedCreditTerms).length
    ) {
      const closestTerm = computeClosestTerm(
        formValues.contractLength,
        installmentApprovedCreditTerms
      );
      const billingFrequency = Object.keys(
        installmentApprovedCreditTerms[closestTerm] ||
          installmentApprovedCreditTerms[`${closestTerm} (co-term)`]
      );
      setBillingOptions(
        billingFrequency.map((paymentFrequency) => ({
          label: paymentFrequencyLabels[paymentFrequency],
          value: paymentFrequency,
        }))
      );
    }
  }, [formValues.contractLength, installmentApprovedCreditTerms]);

  useEffect(() => {
    if (billingOptions.length > 0) {
      const foundObject = billingOptions.find(
        (frequency) => frequency.value === formValues.paymentFrequency
      );
      if (!foundObject) {
        setFieldValue(`${currentProposal}.paymentFrequency`, billingOptions[0].value);
      } else if (formValues.contractLength && formValues.paymentFrequency) {
        setNetTermOptions(
          filterNetTerms(formValues.contractLength, formValues.paymentFrequency)
        );
      }
    }
    // We want to update the net term options oupdated only when payment frequency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingOptions]);

  /*
    Triggers when filteredNetTerms is updated
    if current selected net term is not found in filteredNetTerms
    then sets the 0th index value as net term
  */
  useEffect(() => {
    if (netTermOptions.length > 0) {
      const foundObject = netTermOptions.find(
        (netTerm) => netTerm.value === formValues.paymentTerm
      );
      if (!foundObject) {
        setFieldValue(`${currentProposal}.paymentTerm`, netTermOptions[0].value);
      }
    }
    // We want to update the net term options oupdated only when net terms changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netTermOptions]);

  const handleOnCrmFileModalOpen = async (): Promise<void> => {
    setIsCrmFilesModalOpen(true);
    setIsCrmFilesModalLoading(true);
    handleCrmFilesError();

    const urlsData = await getCustomerCrmFileUrls(customerNumber, crmOpportunityId)
      .then((response) => {
        if (response.error || !response?.length) {
          // handle error
          setCrmFilesHasError(true);
          setCrmFilesErrorType(
            response.error === CRM_FETCH_ERROR_MESSAGES.badRequest
              ? CRM_FETCH_ERROR_TYPES.fetchUrlError
              : CRM_FETCH_ERROR_TYPES.nothingFound
          );
          setIsCrmFilesModalOpen(false);
          return null;
        }
        return response;
      })
      .catch((error) => {
        setCrmFilesHasError(true);
        setCrmFilesErrorType(CRM_FETCH_ERROR_TYPES.fetchUrlError);
        setIsCrmFilesModalOpen(false);
      })
      .finally(() => setIsCrmFilesModalLoading(false));

    setCrmFileUrls(urlsData);
  };

  const handleSetFiles = (files): void => {
    if (uploaderAlert) {
      setUploaderAlert('');
    }
    const currentDocs = orderDocs ?? [];
    const currentDocsFileNames = currentDocs?.length
      ? currentDocs?.map((doc) => doc.name.replace(commonRegex.removePDFExtension, ''))
      : [];
    // filter out files that are already present
    const filteredFiles = files?.filter(
      (doc) => !currentDocsFileNames.includes(doc.name)
    );
    if (filteredFiles?.length < files?.length) {
      setUploaderAlert(DUPLICATE_FILE_ALERT);
    }

    setFieldValue(`${currentProposal}.orderForm`, [...currentDocs, ...filteredFiles]);
  };

  const handleOnSubmitCrmUrls = async (selectedFiles): Promise<void> => {
    if (selectedFiles?.length) {
      setIsCrmFilesModalLoading(true);

      try {
        const response = await getCrmDocumentsContent(
          customerNumber,
          selectedFiles,
          crmOpportunityId
        );
        const receivedFiles = response.files;
        let fileHasError = false; // to check if there is any error in the files
        let filesErrorCount = 0; // to count the number of files with error
        let filesCount = 0; // to count the number of files
        const files = await Promise.all(
          receivedFiles.map(async (file) => {
            if (file.error) {
              fileHasError = true;
              filesErrorCount += 1;
              filesCount += 1;
              return null; // Return null
            }
            filesCount += 1;
            const receivedFileBlob = convertBase64ToFile(file.content); // Process each file
            const blobFile = await createFileFromBlobUrl(receivedFileBlob, file.name); // Create File object
            return blobFile; // Return the created file
          })
        ).then((results) => results.filter((file) => file !== null)); // Filter out null values
        if (fileHasError) {
          setCrmFilesHasError(true);

          // if all files have error then set the error type to fetchFilesError
          // else set the error type to someFilesFailed
          const filesErrorType =
            filesErrorCount === filesCount
              ? CRM_FETCH_ERROR_TYPES.fetchFilesError
              : CRM_FETCH_ERROR_TYPES.someFilesFailed;

          setCrmFilesErrorType(filesErrorType);
        }
        handleSetFiles(files);
      } catch (error) {
        if (error) {
          setCrmFilesHasError(true);
          setCrmFilesErrorType(CRM_FETCH_ERROR_TYPES.fetchFilesError);
          setIsCrmFilesModalOpen(false);
        }
      } finally {
        setIsCrmFilesModalLoading(false);
        setIsCrmFilesModalOpen(false);
      }
    }
  };

  const handleInterestRateChange = (): void => {
    setFieldValue(`${currentProposal}.subsidy`, null);
    handleCrmFilesError();
  };

  return (
    <div className="grid grid-cols-1">
      {shouldRenderUploadFromCrmButton && (
        <UploadDocsFromCrm
          open={isCrmFilesModalOpen}
          onClose={() => setIsCrmFilesModalOpen(false)}
          files={crmFileUrls}
          isLoading={isCrmFilesModalLoading}
          onSubmit={handleOnSubmitCrmUrls}
        />
      )}
      {crmFilesHasError && CRM_FETCH_ERROR_MESSAGES?.[crmFilesErrorType] && (
        <div className="error-message-container">
          <AlertBox
            content={CRM_FETCH_ERROR_MESSAGES[crmFilesErrorType]}
            icon={<SvgIcon name="info" fill="color-red-160" />}
            variant="alert-box"
          />
        </div>
      )}
      <div className="space-y-1 mb-6">
        <div className="flex items-end justify-between file-upload-header-container">
          <Typography variant="paragraph12" color="color-gray-140">
            Order form and other documents
          </Typography>
          {shouldRenderUploadFromCrmButton && (
            <ButtonV2
              variant={{ type: 'ghost', typography: 'paragraph12' }}
              text="Import from CRM"
              type="button"
              className="mb-1"
              onClick={handleOnCrmFileModalOpen}
            />
          )}
        </div>
        <MultiFileUpload
          uploadText="Click or drag PDF files to upload"
          name={`${currentProposal}.orderForm`}
          acceptMultipleFiles
          customAlert={uploaderAlert}
          onClick={handleCrmFilesError}
        />
      </div>
      <div className="mb-6 flex flex-col gap-1">
        <Typography variant="paragraph12" color="color-gray-140">
          Payment options
        </Typography>
        <PaymentOptionSelect
          name={`proposals.${index}.orderType`}
          options={paymentOptions}
          onChange={handleCrmFilesError}
        />
      </div>
      {showProposalFields && (
        <div className="grid grid-cols-2 gap-4">
          <div
            className={`w-full ${
              // This will be used to highlight when there is an error from pricing engine
              // also when there is an actual error in amount field
              showTCVMoneyInputFieldError ? 'tcv-input' : ''
            }`}
          >
            <MoneyInputField
              placeholder="0"
              id={`${currentProposal}.amount`}
              name={`${currentProposal}.amount`}
              label="Total contract value"
              showFieldError={showTCVMoneyInputFieldError}
              maxAllowedValue={MAX_ALLOWED_TCV}
              minAllowedValue={1}
              suffix=""
              prefix={currencySymbol}
              applySetValueDebounce
              disabled={isCustomSchedule}
              onFocus={handleCrmFilesError}
            />

            {!isCustomSchedule && showTCVErrors && (
              <div className="flex flex-col mt-1">
                <Typography variant="paragraph10" color="color-red-160">
                  {currentProposalErrors?.amount}
                </Typography>
                {/* TODO - Nuyaan95, MuhammadAhmadEjaz - Create a separate component to render this button */}
                {showChangeRequestButton ? (
                  <button
                    className="flex items-start w-44 "
                    onClick={handleCreateChangeRequest}
                    type="button"
                  >
                    <Typography variant="paragraph10" color="color-blue-120">
                      Click here to get this limit approved
                    </Typography>
                  </button>
                ) : null}
              </div>
            )}
          </div>
          {showField(proposalFields.contractLength) ? (
            <TermsDropdown
              id={`${currentProposal}.contractLength`}
              name={`${currentProposal}.contractLength`}
              label="Contract length"
              placeholder="Type or select an option"
              options={contractLengthOptions as unknown as OrderContractLengthProps[]}
              showError={showTermError}
              errorMsg={termError || ''}
              closeDropdown={hideTermDropdown}
              handleCloseDropdown={setHideTermDropdown}
              resetOptionsOnBlur
              disabled={shouldDisableTermField}
              onFocus={handleCrmFilesError}
              dropdownOnly={isSyndicated}
            />
          ) : null}
          {showField(proposalFields.paymentFrequency) ? (
            <CustomDropdown
              id={`${currentProposal}.paymentFrequency`}
              name={`${currentProposal}.paymentFrequency`}
              className="flex-1"
              label="Billing frequency"
              placeholder="Select option"
              options={billingOptions}
              autoFocus={false}
              openMenuOnFocus={false}
              preFillFirstOption={false}
              isLoading={false}
              disabled={shouldDisableBillingFrequency}
              onClick={handleCrmFilesError}
            />
          ) : null}
          {showField(proposalFields.paymentTerm) ? renderTermsField() : null}
          {showField(proposalFields.subsidy) &&
          subsidyAllowedOnProduct &&
          formValues.vartanaFinancing ? (
            <div className="gap-1 flex flex-col w-full flex-1">
              <div className="flex justify-between items-center">
                <Typography
                  variant="paragraph12"
                  color={
                    hasProposalErrors && !!currentProposalErrors?.subsidy
                      ? 'color-red-160'
                      : 'color-gray-140'
                  }
                >
                  Subsidy
                </Typography>
                {shouldRenderInterestRate && (
                  <div>
                    <InterestRateSelect
                      name={`${currentProposal}.interestRate`}
                      options={buyerInterestRates || []}
                      label="Interest rate"
                      placeholder="Select"
                      onChange={handleInterestRateChange}
                      disabled={shouldDisableInterestRateSelect}
                      defaultValue={defaultInterestRateValue}
                      lockedMode={isCustomSchedule}
                      dropdownMode={shouldShowInterestRateInDropdown}
                      shouldOpenDropdownOnBottom
                    />
                  </div>
                )}
                {!applyBuyerInterestRate && (
                  <div className=" flex items-center gap-1">
                    <Typography variant="paragraph10" color="color-gray-140">
                      Max subsidy
                    </Typography>
                    {get(formValues, 'maxSubsidyAllowed', false) ? (
                      <div className="flex items-center gap-1.5">
                        <Typography
                          variant="paragraph10"
                          color="color-blue-180"
                          className="subsidy-bold-text"
                        >
                          {getMaxSubsidy()}
                        </Typography>
                        {!isCustomSchedule && (
                          <Button
                            backgroundColor="tertiary"
                            onClick={handleApplyMaxSubsidy}
                            className="!border-0"
                            size="xx-small"
                            type="button"
                          >
                            Apply
                          </Button>
                        )}
                      </div>
                    ) : (
                      <ThreeDotsPlaceholder />
                    )}
                  </div>
                )}
              </div>
              <SubsidyField
                key={formValues.isDollar ? 'dollar subsidy' : 'percentage subsidy'}
                label=""
                id={`${currentProposal}.subsidy`}
                name={`${currentProposal}.subsidy`}
                onUnitChange={() => {
                  handleSubsidyUnitChange();
                }}
                customPrefix={formValues.isDollar ? currencySymbol : ''}
                placeholder="0"
                isCompact={false}
                minAllowedValue={0}
                maxAllowedValue={maxAllowedSubsidy}
                onKeyDown={handleSubsidyKeyPress}
                decimalScale={2}
                defaultSelectValue={
                  formValues.isDollar ? DOLLAR_OPTION : PERCENTAGE_OPTION
                }
                errorMessage={
                  hasProposalErrors && !!currentProposalErrors?.subsidy
                    ? currentProposalErrors?.subsidy
                    : ''
                }
                fixedDecimalScale={false}
                showError={hasProposalErrors && !!currentProposalErrors?.subsidy}
                value={get(formValues, 'subsidy', 0) as unknown as undefined}
                selectContainerClassname="custom-select-container"
                options={[DOLLAR_OPTION, PERCENTAGE_OPTION]}
                disabled={isCustomSchedule}
                onClick={handleCrmFilesError}
              />
            </div>
          ) : null}
          {spiffMode !== SPIFF_MODE.none && (
            <div className="flex-1">
              <InputFieldMoneyFormat
                id={`${currentProposal}.spiffRate`}
                name={`${currentProposal}.spiffRate`}
                label="SPIFF"
                maxAllowedValue={oneWholeTwoDecimal}
                disabled={spiffMode === SPIFF_MODE.fixed || isCustomSchedule}
                placeholder="0"
                showError={hasProposalErrors && !!currentProposalErrors?.spiff}
                helperText={(hasProposalErrors && currentProposalErrors?.spiff) || ''}
                value={get(formValues, 'spiffRate', 0) as unknown as undefined}
                onChange={(e) => setFieldValue(`${currentProposal}.spiffRate`, e.value)}
                decimalScale={2}
                customSuffix="%"
                fullWidth
                onClick={handleCrmFilesError}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ProposalForm;
