import { useCallback, useContext, useState, useRef, useEffect, useMemo } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useField, FieldArray } from 'formik';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';
import { OrderFormContext } from '../../context/OrderContext';
import { BUYER_TYPE } from '../../constants/common.constants';
import { ResellerErrorModal } from './ResellerErrorModal';
import { reportError } from '../../utils/helpers';
import Loader from '../../components/Loader';
import SelectorButton from '../../components/SelectorButton/SelectorButton';
import useResellers from '../../utils/hooks/resellers';
import AutoLoad from '../../components/AutoLoad';
import { ReactComponent as AddAnotherIcon } from '../../assets/circle_cross.svg';
import { ReactComponent as AddAnotherIconDisabled } from '../../assets/circle_cross_disabled.svg';
import { ResellerSection } from './ResellerSection';
import { PayoutSection } from './PayoutSection';

const MAX_ALLOWED_RESELLERS = 5;
const CHECK_RESELLERS = gql`
  query checkResellers($resellers: [InputReseller!]!) {
    resellersExist(resellers: $resellers)
  }
`;

const CHECK_EMAILS = gql`
  mutation checkEmails($emails: [String!]!) {
    checkEmails(emails: $emails)
  }
`;

const YesNoRadio = [
  {
    label: 'Yes',
    id: uuidv4(),
  },
  {
    label: 'No',
    id: uuidv4(),
  },
];

export function ResellerForm({
  formik,
  onBack,
  onNext,
  resellerMode,
  updateResellerMode,
}) {
  const [companyNameOptions, resellersLoading] = useResellers();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [resellers] = useField('resellers');
  const radioRef = useRef();

  const { selectedCustomer } = useContext(OrderFormContext);
  const isDirectBuyer = selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.DIRECT;

  const [checkResellers, { loading: resellersChecking }] = useLazyQuery(CHECK_RESELLERS);
  const [checkEmails, { loading: checkingEmails }] = useMutation(CHECK_EMAILS);

  const enableNext =
    !resellerMode ||
    !isDirectBuyer ||
    (resellerMode &&
      formik.touched.resellers &&
      !formik.errors.resellers &&
      !formik.errors.payoutRecipients &&
      ((formik.values.payoutRecipients.length > 1 && !formik.errors.payoutInformation) ||
        formik.values.payoutRecipients.length === 1));

  const addAnotherReseller = (add) => {
    add({
      uuid: uuidv4(),
      number: '',
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
    });
  };

  useEffect(() => {
    if (resellerMode !== formik.values.isResellerOrder) {
      formik.setFieldValue('isResellerOrder', resellerMode);
    }
  }, [formik, resellerMode]);

  const handleNext = useCallback(async () => {
    if (!resellerMode) onNext();
    else {
      const resellerEmails = resellers.value.map((reseller) => reseller.email);

      const { errors: emailErrors } = await checkEmails({
        variables: { emails: resellerEmails },
      });

      if (emailErrors) {
        formik.setErrors({ resellers: emailErrors });
        return;
      }

      const resellersWithNumbers = resellers.value.map((reseller) => {
        const company = companyNameOptions.find(
          (opt) => opt.value === reseller.companyName
        );
        return {
          ...reseller,
          number: company ? company.number : '',
        };
      });

      formik.setFieldValue('resellers', resellersWithNumbers);

      const { error } = await checkResellers({
        variables: { resellers: resellers.value },
      });

      if (isEmpty(error)) onNext();
      else {
        try {
          const { errors } = JSON.parse(error.message);
          const errorStr = errors.map((e) => JSON.stringify(e)).join('\n');
          reportError(`Partner Matching failed. Errors: ${errorStr}`);
        } catch {
          reportError(`Partner Matching failed. Error: ${error.message}.`);
        }
        setShowErrorModal(true);
      }
    }
  }, [
    resellerMode,
    onNext,
    resellers.value,
    checkEmails,
    formik,
    checkResellers,
    companyNameOptions,
  ]);

  const disableMoreResellers = formik.errors.resellers || !formik.touched.resellers;

  const isMovingToNextStep = useMemo(() => {
    return resellersChecking || checkingEmails;
  }, [resellersChecking, checkingEmails]);

  useEffect(() => {
    setTimeout(() => {
      const el = radioRef.current;
      el?.focus();
    }, 500);
  }, []);

  return (
    <AutoLoad loading={resellersLoading} containerClassName="absolute top-1/2 left-1/2">
      {showErrorModal && <ResellerErrorModal onClose={() => setShowErrorModal(false)} />}
      <Card
        tabindex={0}
        variant="fullWidth"
        content={(
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <Typography variant="heading18">Partner order</Typography>
              <div className="flex flex-col gap-2">
                <Typography variant="paragraph14">
                  Is the customer purchasing through the partner?
                </Typography>
                <div
                  className={`${resellerMode ? 'mb-2' : ''}`}
                  tabIndex={0}
                  ref={radioRef}
                >
                  <SelectorButton
                    name="isResellerOrder"
                    disabled={!isDirectBuyer}
                    preSelectedValues={resellerMode ? ['Yes'] : ['No']}
                    onSelect={(e) => {
                      if (e === 'Yes') updateResellerMode(true);
                      else updateResellerMode(false);
                    }}
                    options={YesNoRadio}
                  />
                </div>
              </div>
              {resellerMode && <hr />}
            </div>
            {resellerMode && (
              <FieldArray name="resellers">
                {({ remove, push }) => {
                  return (
                    <div className="flex flex-col">
                      <main className="divide-y divide-vartana-gray-40-v3">
                        {formik.values.resellers.map((reseller, index) => (
                          <ResellerSection
                            resellerOptions={companyNameOptions}
                            reseller={reseller}
                            index={index}
                            key={reseller.uuid}
                            onRemove={() => remove(index)}
                            haveMultipleResellers={formik.values.resellers.length > 1}
                          />
                        ))}
                      </main>

                      {formik.values.resellers.length < MAX_ALLOWED_RESELLERS &&
                        isDirectBuyer && (
                        <button
                          type="button"
                          disabled={disableMoreResellers}
                          className="flex items-center gap-1 w-fit mt-2 card-subtitle-bold text-vartana-blue-120 disabled:text-vartana-gray-40"
                          onClick={() => addAnotherReseller(push)}
                        >
                          {disableMoreResellers ? (
                            <AddAnotherIconDisabled className="w-8 h-8" />
                          ) : (
                            <AddAnotherIcon className="w-8 h-8" />
                          )}
                            Add another partner
                        </button>
                      )}
                    </div>
                  );
                }}
              </FieldArray>
            )}
            {resellerMode && <hr />}
            {resellerMode && (
              <PayoutSection
                resellers={formik.values.resellers}
                payoutRecipients={formik.values.payoutRecipients}
              />
            )}
          </div>
        )}
      />

      {/* Footer buttons */}
      <div className="w-full flex justify-between">
        <ButtonV2
          variant={{ type: 'ghost', typography: 'paragraph14' }}
          type="button"
          iconLeft="chevron_left"
          onClick={onBack}
          text="Back"
        />
        {isMovingToNextStep ? (
          <Loader className="p-4" />
        ) : (
          <ButtonV2
            iconRight="chevron_right"
            onClick={handleNext}
            type="button"
            text="Next"
            disabled={!enableNext || isMovingToNextStep}
            variant={{ type: 'primary', typography: 'paragraph14' }}
          />
        )}
      </div>
    </AutoLoad>
  );
}

ResellerForm.propTypes = {
  formik: PropTypes.shape({
    isValid: PropTypes.bool,
    dirty: PropTypes.bool,
    setFieldValue: PropTypes.func,
    setErrors: PropTypes.func,
    values: PropTypes.shape({
      resellers: PropTypes.arrayOf(PropTypes.shape({})),
      payoutRecipients: PropTypes.arrayOf(PropTypes.string),
      isResellerOrder: PropTypes.bool,
    }),
    errors: PropTypes.shape({
      resellers: PropTypes.arrayOf(PropTypes.shape({})),
      payoutRecipients: PropTypes.string,
      payoutInformation: PropTypes.string,
    }),
    touched: PropTypes.shape({
      resellers: PropTypes.arrayOf(PropTypes.shape({})),
      payoutRecipients: PropTypes.bool,
      isResellerOrder: PropTypes.bool,
    }),
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  updateResellerMode: PropTypes.func.isRequired,
  resellerMode: PropTypes.bool.isRequired,
};
